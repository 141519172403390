<template>
    <div>
        <ts-page-title
            :title="$t('monitoringQuestion.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('monitoringQuestion.pageTitle'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <ts-loading-banner :loading="loading">
                <div class="col-md-12">
                    <ts-panel>
                        <ts-panel-wrapper>
                            <question-info
                                ref="question_info"
                                v-model="model"
                            />
                            <div slot="footer">
                                <div
                                    class="tw-flex tw-justify-end tw-space-x-2 tw-sh"
                                >
                                    <ts-button
                                        @click="
                                            $router.push({
                                                name: 'monitoring-question'
                                            })
                                        "
                                        >{{ $t("cancel") }}</ts-button
                                    >
                                    <ts-button
                                        outline
                                        color="primary"
                                        @click.prevent="saveAddNew"
                                        :waiting="waiting_save_add_new"
                                    >
                                        {{ $t("saveAddNew") }}</ts-button
                                    >
                                    <ts-button
                                        :waiting="waiting"
                                        color="primary"
                                        @click.prevent="save"
                                    >
                                        {{ $t("save") }}</ts-button
                                    >
                                </div>
                            </div>
                        </ts-panel-wrapper>
                    </ts-panel>
                </div>
            </ts-loading-banner>
        </div>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { isEmpty } from "lodash";
import QuestionInfo from "./question-info.vue";
export default {
    name: "monitoringQuestionCreate",
    components: {
        QuestionInfo
    },
    data() {
        return {
            loading: false,
            model: {
                answer_format: "Radio",
                display_format: null,
                question_cat_id: null,
                question_en: null,
                question_kh: null,
                display_order: 0,
                remark: null,
                answer_lists: [
                    {
                        question_id: 0,
                        answer_id: 0,
                        answer_en: null,
                        answer_kh: null,
                        is_enable_comment_box: false
                    }
                ]
            },
            waiting: false,
            waiting_save_add_new: false
        };
    },
    methods: {
        save() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestion/store",
                    this.model
                )
                .then(response => {
                    this.waiting = false;
                    this.notice({ type: "success", text: response.message });
                    this.$router.push({
                        name: "monitoring-question"
                    });
                })
                .catch(error => {
                    this.waiting = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        saveAddNew() {
            this.errors = new Errors();
            this.waiting_save_add_new = true;
            this.$store
                .dispatch(
                    "creditOperation/monitoringQuestion/store",
                    this.model
                )
                .then(response => {
                    this.clearForm();
                    this.waiting_save_add_new = false;
                    this.notice({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.waiting_save_add_new = false;
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        async fetchData() {
            this.loading = true;
            await this.$refs.question_info.getDisplayFormat();
            await this.$refs.question_info.getQuestionCategory();
            this.loading = false;
        },
        clearForm() {
            this.model.answer_format = "Radio";
            this.model.display_format = null;
            this.model.question_cat_id = null;
            this.model.question_en = null;
            this.model.display_order = 0;
            this.model.question_kh = null;
            this.model.answer_lists = [];
            this.model.remark = null;

            if (
                this.$refs.question_info &&
                this.$refs.question_info.$refs.multiple_choice_radio
            ) {
                this.$refs.question_info.$refs.multiple_choice_radio.defaultAnswer();
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "MONITORING QUESTION ANSWER",
                desc: not.text
            });
        }
    },

    beforeRouteEnter: (to, from, next) => {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        if (isEmpty(to.params)) {
            this.$store.commit(
                "creditOperation/monitoringQuestion/RESET_STATE"
            );
        }
        next();
    }
};
</script>
